
import http from './Http-Common'

class Adv_Pub_DataService {
  getAllAdvertiser() {
    return http.post("/advertiser/findalladvertiser");
  }

  getAllActiveAdvertiser(superpowerbuttonstatus) {
    return http.post("/advertiser/findallactiveadvertiser", {
      superpowerbuttonstatus,
    });
  }
  editAdvertiserState(value) {
    return http.post("/advertiser/handleadvertiserstate", { value });
  }

  getAdvertiserUser(id) {
    return http.get(`/advertiser/connectedusers/${id}`);
  }

  insertAdvPromotionalCode(advertiserId, code, percentage) {
    return http.post(`/advertiser/insertpromotionalcode/${advertiserId}`, {
      code,
      percentage,
    });
  }

  editAdvertiserContactperson(advertiserId, advertisercontact) {
    return http.post(`/advertiser/editcontactperson/${advertiserId}`, {
      advertisercontact,
    });
  }

  editAdvertiserGeneralInformation(id, generalinformation) {
    return http.post(`/advertiser/editgeneralinformation/${id}`, {
      generalinformation,
    });
  }

  getlastfiveweekdata(advertiserId) {
    return http.post(`/advertiser/lastfiveweekdata/${Number(advertiserId)}`);
  }

  getAllPublisher(value) {
    return http.post("/advertiser/findpublisher", { value });
  }
  getAdvertiserbyId(id) {
    return http.post(`/advertiser/getadvertiserbyid/${id}`);
  }
  getAdvertiser(pub_Id) {
    return http.post("/advertiser/getadvertiser", { pub_Id });
  }

  getDataforpdf(advId, token, requested_year) {
    return http.post(`/pdfdata/${advId}`, {
      token: token,
      requested_year: requested_year,
    });
  }

  editAdvertiserdetail(value) {
    return http.post("/advertiser/editadvertiserdetail", { value });
  }

  editadvertisercommission(id, commissionvalue) {
    return http.post(`/advertiser/editadvertisercommissionvalue/${id}`, {
      commissionvalue,
    });
  }

  updatepriority(advertiserId, source, destination) {
    return http.post("/advertiser/updatepriority", {
      advertiserId,
      source,
      destination,
    });
  }

  deleteSale(advertiserId, orderRef) {
    return http.post("/advertiser/deletesale", { advertiserId, orderRef });
  }

  deleteadvertiser(advertiserId) {
    return http.post("/advertiser/deleteadvertiser", { advertiserId });
  }

  uploadLogo(logodetail) {
    return http.post("/advertiser/uploadlogodetail", logodetail);
  }

  countpublishercommission(publisherId, startDate, endDate) {
    return http.post("/advertiser/publishercommission", {
      publisherId,
      startDate,
      endDate,
    });
  }

  Toptenbyweeknumber(weeknumber, advertiserId, requested_year, token) {
    return http.post("/pdfdata/toptenweek", {
      weeknumber,
      advertiserId,
      requested_year,
      token,
    });
  }

  Toptenbymonthnumber(monthnumber, advertiserId, requested_year, token) {
    return http.post("/pdfdata/toptenmonth", {
      monthnumber,
      advertiserId,
      requested_year,
      token,
    });
  }

  getgraphbypublisher(advertiserId, publisherId, token, selectedyear) {
    return http.post("/pdfdata/graphbypublisher", {
      advertiserId,
      publisherId,
      token,
      selectedyear,
    });
  }
}

export default new Adv_Pub_DataService()