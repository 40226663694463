import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import logo_img from "../images/Online_United_Logo.png";
import { logobaseurl } from "../Services/Http-Common";
import { Loading, Grid, Text } from "@nextui-org/react";

import campaignService from "../Services/campaignService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController,
  Tooltip,
  Legend
);

// const generateDateRange = (startDate, endDate, option, totalturnover) => {
//   let start = moment(startDate);
//   const end = moment(endDate);
//   const dates = [];

//   if (option === "day") {
//     while (start.isBefore(end) || start.isSame(end)) {
//       dates.push(start.format("DD-MM-YYYY"));
//       start = start.add(1, "day");
//     }
//   } else if (option === "week") {
//     while (start.isBefore(end) || start.isSame(end)) {
//       dates.push(`Week ${start.isoWeek()}`);
//       start = start.add(1, "week");
//     }
//   }

//   return dates;
// };

const generateDateRange = (startDate, endDate, option, totalturnover, totalsales) => {
  let start = moment(startDate);
  const end = moment(endDate);
  const dates = [];
  const weeklyTurnover = [];
  const weeklySales = []

  if (option === "day") {
    while (start.isBefore(end) || start.isSame(end)) {
      dates.push(start.format("DD-MM-YYYY"));
      start = start.add(1, "day");
    }
    return { dates, turnover: totalturnover, sales : totalsales };
  } else if (option === "week") {
    let weekTurnoverSum = 0;
    let weekSaleSum = 0
    let currentWeek = start.isoWeek();
    let turnoverIndex = 0;

    while (start.isBefore(end) || start.isSame(end)) {
      if (start.isoWeek() !== currentWeek) {
        dates.push(`Week ${currentWeek}`);
        weeklyTurnover.push(weekTurnoverSum);
        weeklySales.push(weekSaleSum)
        weekTurnoverSum = 0;
        weekSaleSum = 0
        currentWeek = start.isoWeek();
      }
      weekTurnoverSum += totalturnover[turnoverIndex] || 0;
      weekSaleSum += totalsales[turnoverIndex] || 0;
      start = start.add(1, "day");
      turnoverIndex++;
    }
    
    // Push the last week's data
    if (weekTurnoverSum > 0 || (start.isoWeek() === currentWeek)) {
      dates.push(`Week ${currentWeek}`);
      weeklyTurnover.push(weekTurnoverSum);
    }
     // Push the last week's data
     if (weekSaleSum > 0 || (start.isoWeek() === currentWeek)) {
      weeklySales.push(weekSaleSum);
    }

    return { dates, turnover: weeklyTurnover, sales : weeklySales };
  }
};

const CampaignReport = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  let campaignId = queryParams.get("campaignId");

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("day");

  const [campaign, setCampaign] = useState({});
  const [campaignreportdata, setCampaignReportData] = useState({});
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart.ctx;

      // Create gradients for the bar charts
      const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
      gradient1.addColorStop(0, "rgba(255,99,132,1)");
      gradient1.addColorStop(1, "rgba(255,99,132,0)");

      const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
      gradient2.addColorStop(0, "rgba(75,192,192,1)");
      gradient2.addColorStop(1, "rgba(75,192,192,0)");

      const gradient3 = ctx.createLinearGradient(0, 0, 0, 400);
      gradient3.addColorStop(0, "rgba(255,206,86,1)");
      gradient3.addColorStop(1, "rgba(255,206,86,0)");

      chart.data.datasets[0].backgroundColor = gradient1;
      chart.data.datasets[1].backgroundColor = gradient2;
      chart.data.datasets[2].backgroundColor = gradient3;
      chart.update();
    }

    campaignService.getcampaignbyid(campaignId).then(async (response) => {
      if (response.status === 200) {
        const campaign = response.data.data;
        const image = logobaseurl + campaign?.logourl;
        campaign.logoBlobUrl = await getBlobUrl(image);
        setCampaign(campaign);
        setIsLoading(false);
      }
    });

    campaignService.generatereport(campaignId).then((response) => {
      if (response.status === 200) {
        setCampaignReportData(response.data);
      }
    });
  }, [campaignId]);

  const graphresult = generateDateRange(
    moment(campaign?.startDate)
      .subtract(campaignreportdata?.timePeriod, "days")
      .format("YYYY-MM-DD"),
    moment(campaign?.endDate)
      .add(campaignreportdata?.timePeriod, "days")
      .format("YYYY-MM-DD"),
    selectedOption,
    campaignreportdata?.totalturnover,
    campaignreportdata?.totalsales
  );

  const handleToggle = (option) => {
    setSelectedOption(option);
  };
  // Define the background colors for each segment
  const backgroundColors = [
    ...Array(campaignreportdata?.timePeriod).fill("rgba(255, 99, 132, 0.5)"), // Light Red
    ...Array(campaignreportdata?.timePeriod).fill("rgba(75, 192, 192, 0.5)"), // Light Green
    ...Array(campaignreportdata?.timePeriod).fill("rgba(255, 206, 86, 0.5)"), // Light Yellow
  ];

  // Define the border colors for each segment
  const borderColors = [
    ...Array(campaignreportdata?.timePeriod).fill("rgba(255, 99, 132, 1)"), // Dark Red
    ...Array(campaignreportdata?.timePeriod).fill("rgba(75, 192, 192, 1)"), // Dark Green
    ...Array(campaignreportdata?.timePeriod).fill("rgba(255, 206, 86, 1)"), // Dark Yellow
  ];
  const data = {
    labels: graphresult?.dates,
    datasets: [
      {
        type: "bar",
        label: "Turnover",
        backgroundColor: backgroundColors,
        data: graphresult.turnover,
        borderColor: borderColors,
        borderWidth: 1,
      },
      {
        type: "line",
        label: "Sales",
        borderColor: "rgba(0, 100, 0, 1)", // Dark Red
        borderWidth: 2,
        fill: false,
        data: graphresult?.sales,
        pointBackgroundColor: "rgba(139, 0, 0, 0.5)",
        pointBorderColor: "rgba(139, 0, 0, 1)",
        pointHoverBackgroundColor: "rgba(139, 0, 0, 1)",
        pointHoverBorderColor: "rgba(139, 0, 0, 1)",
        yAxisID: "y1",
        stack: "y1",
      },
    ],
  };

  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        campaign?.advertiserName + "_" + campaign?.title + "_report.pdf"
      );
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      title: {
        display: true,
        text: "Campaign Graph",
        font: {
          size: 20,
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          size: 16,
          weight: "bold",
        },
        bodyFont: {
          size: 14,
        },
        footerFont: {
          size: 12,
        },
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
      y1: {
        position: "right",
      },
    },
  };

  // Utility function to sum all values of an array
  const sumArray = (array) => {
    if (!array || array.length === 0) return 0;
    return array.reduce((acc, value) => acc + Number(value), 0);
  };

  const getBlobUrl = async (url) => {
    try {
      const response = await fetch(url);
      // Convert the image to a Blob
      const blob = await response.blob();
      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } catch (error) {
      alert("Can not load the image");
    }
  };

  return (
    <>
      {isloading ? (
        <Grid justify="center">
          <center>
            <Text blockquote>
              Please Wait, We are Generating Report for You.
              <Text blockquote>Thank you for your patience.</Text>
            </Text>
            <Loading type="points" />
          </center>
        </Grid>
      ) : (
        <div>
          <div id="pdf-content">
            <div style={{ backgroundColor: "white" }}>
              <div>
                <div
                  style={{ paddingTop: "1rem", backgroundColor: "white" }}
                  className="d-flex justify-content-between"
                >
                  <div>
                    <img
                      src={logo_img}
                      alt="logo img"
                      style={{ width: "15rem" }}
                    />
                  </div>
                  <div>
                    <h1 style={{ paddingTop: "1rem" }}>
                      {campaign?.advertiserName}
                    </h1>
                  </div>
                  <div>
                    <img
                      src={campaign?.logoBlobUrl}
                      alt="logo img"
                      style={{ width: "15rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container mt-5">
              <div className="card">
                <div className="card-body">
                  <div className="mb-3">
                    <strong>Title : </strong>
                    <span className="card-text">{campaign?.title}</span>
                  </div>
                  <div className="mb-3">
                    <strong>Target Group : </strong>
                    <span className="card-text">
                      {campaign?.selectedPublishers.length === 0
                        ? "All Publishers"
                        : campaign?.selectedPublishers
                            .map((publisher) => publisher.label)
                            .join(", ")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <strong>Details : </strong>
                    <span className="card-text">{campaign?.description}</span>
                  </div>
                  <div className="mb-3">
                    <strong>Time Period : </strong>
                    <span className="card-text">
                      {moment(campaign?.startDate).format("DD-MM-YYYY")} to{" "}
                      {moment(campaign?.endDate).format("DD-MM-YYYY")}
                    </span>
                  </div>

                  <div className="row">
                    {" "}
                    <div className="col-md-4">
                      <div className="mb-3">
                        <strong>Average Sale : </strong>
                        <ul>
                          <li>
                            Before Campaign :{" "}
                            {(
                              sumArray(campaignreportdata?.beforesale) /
                              Number(campaignreportdata?.timePeriod)
                            ).toFixed(2)}
                          </li>
                          <li>
                            During Campaign :{" "}
                            {(
                              sumArray(campaignreportdata?.currentsale) /
                              Number(campaignreportdata?.timePeriod)
                            ).toFixed(2)}
                          </li>
                          <li>
                            After Campaign:{" "}
                            {(
                              sumArray(campaignreportdata?.aftersale) /
                              Number(campaignreportdata?.timePeriod)
                            ).toFixed(2)}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <strong>Average Turnover : </strong>
                        <ul>
                          <li>
                            Before Campaign :{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.beforeturnover) /
                                Number(campaignreportdata?.timePeriod)
                              ).toFixed(2)
                            )}
                          </li>
                          <li>
                            During Campaign :{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.currentturnover) /
                                Number(campaignreportdata?.timePeriod)
                              ).toFixed(2)
                            )}
                          </li>
                          <li>
                            After Campaign:{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.afterturnover) /
                                Number(campaignreportdata?.timePeriod)
                              ).toFixed(2)
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <strong>Average Order Value : </strong>
                        <ul>
                          <li>
                            Before Campaign :{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.beforeturnover) /
                                sumArray(campaignreportdata?.beforesale)
                              ).toFixed(2)
                            )}
                          </li>
                          <li>
                            During Campaign :{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.currentturnover) /
                                sumArray(campaignreportdata?.currentsale)
                              ).toFixed(2)
                            )}
                          </li>
                          <li>
                            After Campaign:{" "}
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              (
                                sumArray(campaignreportdata?.afterturnover) /
                                sumArray(campaignreportdata?.aftersale)
                              ).toFixed(2)
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex justify-content-center">
              <div
                className="btn-group toggle-switch"
                role="group"
                aria-label="Toggle switch"
              >
                <button
                  type="button"
                  className={`btn btn-outline-success ${
                    selectedOption === "day" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("day")}
                >
                  By Day
                </button>
                <button
                  type="button"
                  className={`btn btn-outline-success ${
                    selectedOption === "week" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("week")}
                >
                  By Week
                </button>
              </div>
            </div>
            <div
              ref={chartContainerRef}
              style={{ width: "80%", margin: "0 auto", padding: "50px" }}
            >
              <Chart ref={chartRef} type="bar" data={data} options={options} />
            </div>
          </div>
          <button
            onClick={downloadPDF}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Download PDF
          </button>
        </div>
      )}
    </>
  );
};

export default CampaignReport;
