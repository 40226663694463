import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BsTrashFill, BsInfoCircleFill } from "react-icons/bs";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { Bar } from "react-chartjs-2";

import {
  Card,
  Grid,
  Text,
  Button,
  Input,
  Spacer,
  Collapse,
  Checkbox,
  Tooltip,
  Avatar,
  Dropdown,
  Textarea,
  Row,
  Col,
  Modal,
  Popover,
} from "@nextui-org/react";
import ReactCountryFlag from "react-country-flag";

import Task_Service from "../Services/taskService";
import NotesService from "../Services/NotesService";
import moment from "moment";
import jwt from "jwt-decode";

import Adv_Pub_DataService from "../Services/advertiserService";
import Navbar from "./Navigationbar";
import PersonalTask from "./PersonalTask";
import PersonalNotes from "./PersonalNotes";
import { logobaseurl } from "../Services/Http-Common";
import advertiserService from "../Services/advertiserService";
import publisherService from "../Services/publisherService";

function Advertiser(props) {
  const location = useLocation();

  const userId = jwt(localStorage.getItem("TOU")).userId;
  const usertype = jwt(localStorage.getItem("TOU")).accessCode;
  const history = useHistory();
  const [advertiserArray, setAdvertiserArray] = useState(
    location.state?.advertiserArray ? location.state?.advertiserArray : []
  );
  const [advertiserId, setAdvertiserId] = useState();
  const [advertiserName, setAdvertiserName] = useState("");
  const [id, setId] = useState(new URLSearchParams(location.search).get("Id"));
  const [network, setNetwork] = useState();
  const [bi_report, setBiReport] = useState("");
  const [logourl, setLogoUrl] = useState();
  const [daysback, setDaysBack] = useState();
  const [token, setToken] = useState();
  const [advstartdate, setAdvstartdate] = useState();
  const [promotionalCodes, setPromotionalCodes] = useState([]);
  const [generalinformation, setGeneralInformation] = useState();
  const [editedCommission, setEditedCommission] = useState();

  // For General Information of Advertiser
  const [isEditing, setIsEditing] = useState(false);

  const [managerlist, setManagerList] = useState([]);
  const [supporterlist, setSupporterList] = useState([]);
  const [advertisercontact, setAdvertiserContact] = useState([]);
  const [isEditingcommission, setIsEditingCommission] = useState(false);

  const [dailytask, setDailyTask] = useState([]);
  const [weeklytask, setWeeklyTask] = useState([]);
  const [monthlytask, setMonthlyTask] = useState([]);
  const [personaltask, setPersonalTask] = useState([]);
  const [notes, setNotes] = useState([]);
  const [advpubnotes, setAdvPubNotes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [allnetwork, setAllNetwork] = useState([]);

  const [lastweekpub, setLastweekpub] = useState([]);
  const [yearpub, setYearpub] = useState([]);
  const [weekdata, setWeekdata] = useState([]);

  // for Promotional Conditions
  const [showOptions, setShowOptions] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [userInput, setUserInput] = useState("");
  const [allpublishertype, setAllpublisherType] = useState([]);
  const [inputRequired, setInputRequired] = useState(false);

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  const [visible, setVisible] = useState(false);

  useEffect(
    (e) => {
      Adv_Pub_DataService.getAdvertiserbyId(id).then((response) => {
        setAdvertiserId(response.data.data?.advertiserId);
        setAdvertiserName(response.data.data?.advertiserName);
        setLogoUrl(response.data.data?.logourl);
        setDaysBack(response.data.data?.daysback);
        setToken(response.data.data?.token);
        setNetwork(response.data.data?.network);
        setBiReport(response.data.data?.bi_report);
        setAdvstartdate(response.data.data?.startdate);
        setEditedCommission(response.data.data?.standardcommission);
        setPromotionalCodes(response.data.data?.promotionalCodes);
        setGeneralInformation(response.data.data?.generalinformation);
      });

      Adv_Pub_DataService.getAllActiveAdvertiser(null).then((response) => {
        if (response.status === 200) {
          if (advertiserArray.length === 0) {
            setAdvertiserArray(response.data.data.data);
          }
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      Adv_Pub_DataService.getAdvertiserUser(id).then((response) => {
        if (response.status === 200) {
          setManagerList(response.data.data.managerlist);
          setSupporterList(response.data.data.supporterlist);

          if (response.data.data?.contactperson.length === 0) {
            setAdvertiserContact([
              { name: "", email: "", telephone: "" },
              { name: "", email: "", telephone: "" },
            ]);
          } else if (response.data.data?.contactperson.length === 1) {
            setAdvertiserContact([
              response.data.data?.contactperson[0],
              { name: "", email: "", telephone: "" },
            ]);
          } else {
            setAdvertiserContact(response.data.data.contactperson);
          }
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      Adv_Pub_DataService.getlastfiveweekdata(Number(advertiserId)).then(
        (response) => {
          if (response.status === 200) {
            setWeekdata(response.data.data.last_week_data);
            setLastweekpub(response.data.data.last_week_publisher);
            setYearpub(response.data.data.all_over_publisher);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        }
      );

      Task_Service.getalltasks(Number(advertiserId)).then((response) => {
        if (response.status === 200) {
          setDailyTask([]);
          setWeeklyTask([]);
          setMonthlyTask([]);
          setPersonalTask([]);
          setDailyTask(response.data.tasks.dailytask);
          setWeeklyTask(response.data.tasks.weeklytask);
          setMonthlyTask(response.data.tasks.monthlytask);
          setPersonalTask(response.data.tasks.personaltask);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      // get all notes
      NotesService.getallnotes(Number(advertiserId)).then((response) => {
        if (response.status === 200) {
          setNotes(response.data.notes);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      publisherService.getallnetwork().then((response) => {
        if (response.status === 200) {
          setAllNetwork(response.data.allnetwork);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      publisherService.getAdvPubNote(id).then((response) => {
        if (response.status === 200) {
          setAdvPubNotes(response.data?.advpubnote);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      });

      publisherService.getallpublishertype().then(async (response) => {
        if (response.status === 200) {
          setAllpublisherType(response.data.allpublishertype);
        }
      });
    },
    [history, advertiserId, userId, id, advertiserArray]
  );

  // // for Promotional Conditions
  const handleAddNewClick = () => {
    setShowOptions(true);
  };

  const handleItemListChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "#") {
      // If "Select Code" is selected, don't proceed to confirmation
      setShowTextInput(false);
      setShowConfirmation(false);
      setInputRequired(false);
    } else {
      setSelectedItem(selectedValue);
      setShowTextInput(false);
      setShowConfirmation(true);
      setInputRequired(false);
    }
  };

  const handleConfirmation = async (response) => {
    setShowConfirmation(false);

    if (response === "yes") {
      setShowTextInput(true);
      setInputRequired(true); // Set input as required
    } else {
      // Handle 'No' response
      setShowTextInput(false);
      setInputRequired(false); // Set input as required
      await Adv_Pub_DataService.insertAdvPromotionalCode(
        Number(advertiserId),
        selectedItem,
        userInput
      ).then((response) => {
        if (response.status === 200) {
          setInputRequired(false);
          setUserInput("");
          setSelectedItem("#");
          setShowTextInput(false);
          setShowConfirmation(false);
          setShowOptions(false);

          // Update promotionalCodes array
          setPromotionalCodes((prevCodes) => {
            const existingPromoCodeIndex = prevCodes.findIndex(
              (promoCode) => promoCode.code === selectedItem
            );

            if (existingPromoCodeIndex !== -1) {
              // Update existing object
              const updatedCodes = [...prevCodes];
              updatedCodes[existingPromoCodeIndex] = {
                code: selectedItem,
                percentage: null,
              };
              return updatedCodes;
            } else {
              // Add new object
              return [...prevCodes, { code: selectedItem, percentage: null }];
            }
          });
        }
      });
    }
  };

  // for general information of Advertiser

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    // Call the API to save data
    // For the sake of this example, log the data instead of making an actual API call
    await advertiserService
      .editAdvertiserGeneralInformation(id, generalinformation)
      .then((response) => {
        if (response.status === 200) {
          const index = advertiserArray.findIndex(
            (advertiser) => advertiser._id === id
          );
          if (index !== -1) {
            // Update the commission for the matched object in place
            advertiserArray[index].generalinformation = generalinformation;
          }
          setGeneralInformation(generalinformation);
          setIsEditing(false);
        }
      });
  };

  const handleSubmit = async () => {
    // Handle the user input as needed
    await advertiserService
      .insertAdvPromotionalCode(Number(advertiserId), selectedItem, userInput)
      .then((response) => {
        if (response.status === 200) {
          setInputRequired(false);
          setUserInput("");
          setSelectedItem("#");
          setShowTextInput(false);
          setShowConfirmation(false);
          setShowOptions(false);

          // Update promotionalCodes array
          setPromotionalCodes((prevCodes) => {
            const existingPromoCodeIndex = prevCodes.findIndex(
              (promoCode) => promoCode.code === selectedItem
            );

            if (existingPromoCodeIndex !== -1) {
              // Update existing object
              const updatedCodes = [...prevCodes];
              updatedCodes[existingPromoCodeIndex] = {
                code: selectedItem,
                percentage: userInput,
              };
              return updatedCodes;
            } else {
              // Add new object
              return [
                ...prevCodes,
                { code: selectedItem, percentage: userInput },
              ];
            }
          });
        }
      });
  };

  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };

  const handleInputChange = (personIndex, field, value) => {
    const updatedPersons = [...advertisercontact];
    updatedPersons[personIndex][field] = value;
    setAdvertiserContact(updatedPersons);
  };
  const handleblurcommission = async () => {
    await advertiserService
      .editadvertisercommission(id, editedCommission)
      .then((response) => {
        if (response.status === 200) {
          const index = advertiserArray.findIndex(
            (advertiser) => advertiser._id === id
          );
          if (index !== -1) {
            // Update the commission for the matched object in place
            advertiserArray[index].standardcommission = editedCommission;
          }
          setEditedCommission(editedCommission);
          setIsEditingCommission(false);
        }
      });
  };

  const handleeditcontactperson = async (advertisercontact) => {
    await advertiserService
      .editAdvertiserContactperson(Number(advertiserId), advertisercontact)
      .then((response) => {
        if (response.status === 200) {
          closeHandler();
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  let week = [];
  let weekdatasale = [];
  let weekdataapproved = [];
  let weekdatadeclined = [];
  let weekdatapending = [];

  weekdata.forEach((x) => {
    week.push(x.week);
    weekdatasale.push(x.sales);
    weekdataapproved.push(x.week_approved);
    weekdatadeclined.push(x.week_declined);
    weekdatapending.push(x.week_pending);
  });
  const week_state = {
    labels: week,
    datasets: [
      {
        type: "line",
        label: "Weekly Sales",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(61, 64, 235)",
        borderColor: "rgb(61, 64, 235)",
        borderWidth: 2,
        data: weekdatasale,
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "Approved",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgba(32,108,32)",
        borderColor: "rgba((32,108,32))",
        borderWidth: 2,
        data: weekdataapproved,
        yAxisID: "y",
        ordered: 1,
      },
      {
        type: "bar",
        label: "Declined",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(252, 28, 3)",
        borderColor: "rgb((252, 28, 3))",
        borderWidth: 2,
        data: weekdatadeclined,
        yAxisID: "y",
        ordered: 1,
      },
      {
        type: "bar",
        label: "Pending",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(100, 107, 95)",
        borderColor: "rgb((100, 107, 95))",
        borderWidth: 2,
        data: weekdatapending,
        yAxisID: "y",
        ordered: 3,
      },
    ],
  };

  function handleEditNote(index) {
    setEditingIndex(index);
  }

  function handleBlurNote(index) {
    const notedata = notes[index];
    if (notedata.notedetail !== "") {
      NotesService.updateNotes(notedata._id, notedata.notedetail)
        .then((response) => {
          if (response.status !== 200) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    }
    setEditingIndex(-1);
  }

  function handleChangeNote(e, index) {
    const newItems = [...notes];
    notes[index].notedetail = e.target.value;
    setNotes(newItems);
  }
  const dailyhandleEnd = (result) => {
    const items = Array.from(dailytask);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDailyTask(items);
    Task_Service.update_task_tasknumber(
      reorderedItem._id,
      result.destination.index
    )
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const weeklyhandleEnd = (result) => {
    const items = Array.from(weeklytask);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setWeeklyTask(items);
    Task_Service.update_task_tasknumber(
      reorderedItem._id,
      result.destination.index
    )
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const monthlyhandleEnd = (result) => {
    const items = Array.from(monthlytask);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setMonthlyTask(items);
    Task_Service.update_task_tasknumber(
      reorderedItem._id,
      result.destination.index
    )
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const getDatafromPersonalTask = (newpersonaltask) => {
    if (newpersonaltask[0].createdby === userId) {
      setPersonalTask([newpersonaltask[0], ...personaltask]);
    }
  };

  const getDatafromPersonalNotes = (personalnote) => {
    const idxObj = notes.findIndex((object) => {
      return object._id === personalnote._id;
    });
    if (idxObj <= -1) {
      setNotes([personalnote, ...notes]);
    } else {
      notes.splice(idxObj, 1);
      notes.splice(idxObj, 0, personalnote);
      setNotes([...notes]);
    }
  };

  const handletaskdone = (isdone, taskId, tasktype) => {
    Task_Service.update_task_done(isdone, taskId, advertiserId, tasktype)
      .then((response) => {
        if (response.status === 200) {
          if (tasktype === "daily") {
            const dailyIndex = dailytask.findIndex((obj) => obj._id === taskId);
            dailytask[dailyIndex].isdone.isdone = false;
            const tempdailyArr = [...dailytask];
            setDailyTask(tempdailyArr);
          }
          if (tasktype === "weekly") {
            const weeklyIndex = weeklytask.findIndex(
              (obj) => obj._id === taskId
            );
            weeklytask[weeklyIndex].isdone.isdone = false;
            const tempweeklyArr = [...weeklytask];
            setWeeklyTask(tempweeklyArr);
          }
          if (tasktype === "monthly") {
            const monthlyIndex = monthlytask.findIndex(
              (obj) => obj._id === taskId
            );
            monthlytask[monthlyIndex].isdone.isdone = false;
            const tempmonthlyyArr = [...monthlytask];
            setMonthlyTask(tempmonthlyyArr);
          }
          if (tasktype === "personal") {
            const Index = personaltask.findIndex((obj) => obj._id === taskId);
            personaltask[Index].isdone = isdone;
            const tempPersonalArr = [...personaltask];
            setPersonalTask(tempPersonalArr);
          }
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const handledeletetask = (taskId, tasktype) => {
    Task_Service.deletetask(taskId, tasktype)
      .then(async (response) => {
        if (response.status === 200) {
          if (tasktype === "daily") {
            const index = dailytask.findIndex(function (i) {
              return i._id === taskId;
            });
            if (index >= 0) {
              let tempDailyArr = [...dailytask];
              tempDailyArr.splice(index, 1);
              setDailyTask(tempDailyArr);
            }
          } else if (tasktype === "weekly") {
            const index = weeklytask.findIndex(function (i) {
              return i._id === taskId;
            });
            if (index >= 0) {
              let tempWeeklyArr = [...weeklytask];
              tempWeeklyArr.splice(index, 1);
              setWeeklyTask(tempWeeklyArr);
            }
          } else if (tasktype === "monthly") {
            const index = monthlytask.findIndex(function (i) {
              return i._id === taskId;
            });
            if (index >= 0) {
              let tempMonthlyArr = [...monthlytask];
              tempMonthlyArr.splice(index, 1);
              setMonthlyTask(tempMonthlyArr);
            }
          }
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  return (
    <div style={{ overflowY: "hidden", overflowX: "hidden" }}>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <Dropdown>
          <Dropdown.Button flat>Select Other Advertiser</Dropdown.Button>
          <Dropdown.Menu>
            {advertiserArray.map((advertiser, index) => {
              return (
                <Dropdown.Item key={advertiser.advertiserId}>
                  <button
                    className="dropdown-item"
                    type="button"
                    tabIndex={index}
                    onClick={(e) => {
                      setAdvertiserId(Number(advertiser.advertiserId));
                      setNetwork(advertiser.network);
                      setBiReport(advertiser.bi_report);
                      setAdvertiserName(advertiser.advertiserName);
                      setLogoUrl(advertiser.logourl);
                      setDaysBack(advertiser.daysback);
                      setToken(advertiser.token);
                      setAdvstartdate(advertiser.startdate);
                      setId(advertiser._id);
                      setEditedCommission(advertiser.standardcommission);
                      setGeneralInformation(advertiser.generalinformation);
                      setPromotionalCodes(advertiser.promotionalCodes);
                      // Update the URL with the new advertiserId
                      history.replace({
                        pathname: "/advertiser",

                        // state: {
                        //   _id: advertiser._id,
                        //   advertiserId: advertiser.advertiserId,
                        //   advertiserName: advertiser.advertiserName,
                        //   logourl: advertiser.logourl,
                        //   daysback: advertiser.daysback,
                        //   token: advertiser.token,
                        //   network: advertiser.network,
                        //   advertiserArray: advertiserArray,
                        //   startdate: advertiser.startdate,
                        //   standardcommission: advertiser.standardcommission,
                        //   promotionalCodes: advertiser.promotionalCodes,
                        //   generalinformation: advertiser.generalinformation,
                        // },
                        search: `Id=${advertiser._id}`,
                      });
                    }}
                  >
                    {advertiser.advertiserName}
                  </button>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div
        style={{
          backgroundImage: `linear-gradient(to right, rgba(7, 227, 155, 255), rgba(15, 144, 150, 255))`,
        }}
      >
        <Grid.Container gap={2}>
          <Card css={{ margin: "$5" }}>
            <Row>
              <Col>
                <Grid xl={6} xs={20} sm={10}>
                  <div className="container">
                    <ReactCountryFlag
                      countryCode={advertiserName.split(" ").pop()}
                      svg
                      style={{
                        width: "4rem",
                        height: "4rem",
                      }}
                      title="US"
                    />{" "}
                    {logourl ? (
                      <img src={logobaseurl + logourl} alt="logo img" />
                    ) : (
                      "No Logo"
                    )}
                  </div>
                </Grid>
                {/* <Grid xl={5} sm={20}> */}

                {/* </Grid> */}
                <Grid xl={6} xs={20} sm={10}>
                  <div className="container">
                    <div className="col">
                      <div className="row">
                        <Text b color="black">
                          Contact Persons
                          <span>
                            {" "}
                            <AiOutlineUsergroupAdd
                              size={20}
                              color="#04999e"
                              onClick={(e) => handler()}
                            />
                          </span>
                        </Text>

                        <Row>
                          {advertisercontact.map((person, index) => (
                            <Grid tabIndex={index}>
                              <Popover placement="right">
                                <Popover.Trigger>
                                  <Avatar
                                    text={
                                      person?.name !== "" ? person?.name : "-"
                                    }
                                    color="gradient"
                                    textColor="white"
                                  />
                                </Popover.Trigger>
                                <Popover.Content>
                                  <Card>
                                    <div className="px-1 py-2">
                                      <div className="text-small font-bold">
                                        Name -{" "}
                                        {person?.name !== "" ? (
                                          <span>{person?.name}</span>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            Not Given
                                          </span>
                                        )}{" "}
                                        <br />
                                        Mail -{" "}
                                        {person?.email !== "" ? (
                                          <a href={"mailto:" + person?.email}>
                                            {" "}
                                            {person?.email}
                                          </a>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            Not Given
                                          </span>
                                        )}{" "}
                                        <br />
                                        Tel -{" "}
                                        {person?.telephone !== "" ? (
                                          <span>{person?.telephone}</span>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            Not Given
                                          </span>
                                        )}{" "}
                                      </div>
                                    </div>
                                  </Card>
                                </Popover.Content>
                              </Popover>
                              <Tooltip
                                placement="right"
                                content={"Time - " + person.name}
                                trigger="hover"
                              ></Tooltip>
                            </Grid>
                          ))}
                        </Row>
                        <Spacer y={1} />

                        {managerlist.length > 0 ? (
                          <div>
                            <Text b color="black">
                              Accout Manager OU :
                            </Text>
                            {managerlist.map((manager, index) => {
                              return (
                                <Text h5 tabIndex={index}>
                                  <a href={"mailto:" + manager}> {manager}</a>
                                </Text>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}

                        {supporterlist.length > 0 ? (
                          <div>
                            <Text b color="black">
                              Supporter OU :
                            </Text>
                            {supporterlist.map((suporter, index) => {
                              return (
                                <Text h5 tabIndex={index}>
                                  <a href={"mailto:" + suporter}>{suporter}</a>
                                </Text>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Col>{" "}
              <Grid xl={11} xs={20} sm={10} justify="left">
                <div className="container mt-4">
                  <div className="row">
                    <div className="row-md-6">
                      <div
                        onDoubleClick={() => setIsEditingCommission(true)}
                        onBlur={handleblurcommission}
                      >
                        Standard Commission:{" "}
                        {isEditingcommission ? (
                          <Input
                            type="number"
                            value={editedCommission}
                            onChange={(e) =>
                              setEditedCommission(e.target.value)
                            }
                            autoFocus
                          />
                        ) : (
                          <span>{`${editedCommission}%`}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6" style={{ position: "sticky" }}>
                      {promotionalCodes.map((PC, index) => (
                        <ul
                          key={index}
                          tabIndex={index}
                          style={{
                            color:
                              PC.percentage === null || PC.percentage === 0
                                ? "red"
                                : "green",
                          }}
                        >
                          {PC.code}{" "}
                          {PC.percentage === null ||
                          PC.percentage === 0 ? null : (
                            <span> :: {PC.percentage} % </span>
                          )}
                        </ul>
                      ))}
                    </div>

                    <div className="col-md-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddNewClick}
                      >
                        Add New
                      </button>
                      {showOptions && (
                        <div className="d-flex flex-column mt-3">
                          <div className="mb-3">
                            <label htmlFor="itemList">
                              Select any Promotional Code:
                            </label>
                            <select
                              className="form-control"
                              id="itemList"
                              onChange={handleItemListChange}
                            >
                              <option value="#">Select Code</option>
                              {allpublishertype.map((pub, index) => (
                                <option
                                  key={index}
                                  value={pub}
                                  tabIndex={index}
                                >
                                  {pub}
                                </option>
                              ))}
                            </select>
                          </div>

                          {showConfirmation && (
                            <div className="mb-3">
                              <p>Do you want to proceed?</p>
                              <button
                                className="btn btn-success"
                                onClick={() => handleConfirmation("yes")}
                              >
                                Yes
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleConfirmation("no")}
                              >
                                No
                              </button>
                            </div>
                          )}

                          {showTextInput && (
                            <div>
                              <div className="form-group">
                                <label htmlFor="userInput">
                                  Enter a number:
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="userInput"
                                  onChange={(e) => setUserInput(e.target.value)}
                                  required={inputRequired}
                                />
                              </div>
                              {userInput && (
                                <button
                                  className="btn btn-primary"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xl={3} xs={20} sm={6} justify="right">
                <Card
                  variant="bordered"
                  isHoverable
                  css={{ bgColor: "#ededed" }}
                >
                  <Card.Body>
                    <Grid>
                      <div className="container">
                        <div className="row">
                          {network === "awin" ||
                          network === "retailads" ||
                          network === "cj" ? (
                            <div className="col">
                              {/* <div className="row"> */}
                              <Grid.Container gap={2}>
                                {/* <Grid xl={4.8} xs={10} sm={7.5}>
                                      <Input
                                        width="100px"
                                        label="Start Date"
                                        type="date"
                                        onChange={(e) =>
                                          setStartDate(e.target.value)
                                        }
                                      />
                                    </Grid>
                                    <Spacer x={-1}></Spacer>
                                    <Grid xl={2} xs={10} sm={5}>
                                      <Input
                                        width="100px"
                                        label="End Date"
                                        type="date"
                                        onChange={(e) =>
                                          setEndDate(e.target.value)
                                        }
                                      />
                                    </Grid> */}

                                <Grid>
                                  <Link
                                    to={{
                                      pathname: "/adv_pub",
                                      state: {
                                        startDate: moment()
                                          .startOf("year")
                                          .format("YYYY-MM-DD"),
                                        endDate: moment()
                                          .endOf("year")
                                          .format("YYYY-MM-DD"),
                                        advertiserId: advertiserId,
                                        advertiserName: advertiserName,
                                      },
                                    }}
                                  >
                                    <button
                                      type="button"
                                      class="btn "
                                      style={{
                                        background: "#04999e",
                                        color: "white",
                                        minWidth: "11rem",
                                      }}
                                    >
                                      Publishers
                                    </button>
                                  </Link>
                                </Grid>
                              </Grid.Container>

                              {network === "awin" ? (
                                <Grid>
                                  <a
                                    href={
                                      "https://ui.awin.com/merchant/" +
                                      advertiserId +
                                      "/report/transactions/export/network/awin/dateType/transaction/transactionType/pending/perPage/10/page/1/timezone/Europe-Paris/reportName/transactions/merchantId/" +
                                      advertiserId +
                                      "/transactionState/pending/pageNumber/1/reportIdentifier/transactionsReport/dynamicDateRange/custom/start/" +
                                      advstartdate +
                                      "/end/" +
                                      moment()
                                        .subtract(daysback, "days")
                                        .format("YYYY-MM-DD") +
                                      "/hideBonusTransactions/1/amended/0/exportType/excel"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      css={{
                                        bgColor: "#04999e",
                                        minWidth: "11rem",
                                      }}
                                      auto
                                    >
                                      Sales List
                                    </Button>
                                  </a>
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid>
                                <Link
                                  to={
                                    "/advertiserservice?id=" +
                                    advertiserId +
                                    "&token=" +
                                    token
                                  }
                                  target="_blank"
                                >
                                  <Button
                                    css={{
                                      bgColor: "#04999e",
                                      minWidth: "11rem",
                                    }}
                                    auto
                                  >
                                    Report
                                  </Button>
                                </Link>
                              </Grid>

                              {network === "awin" ? (
                                <Grid>
                                  <a
                                    href={
                                      "https://ui.awin.com/awin/merchant/" +
                                      advertiserId +
                                      "/affiliate-approval"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button
                                      css={{
                                        bgColor: "#04999e",
                                        minWidth: "11rem",
                                      }}
                                      auto
                                    >
                                      Publisherfreigaben
                                    </Button>
                                  </a>
                                </Grid>
                              ) : (
                                <></>
                              )}

                              {network === "awin" ? (
                                <Grid>
                                  <a
                                    href={
                                      "https://ui.awin.com/merchant/" +
                                      advertiserId +
                                      "/report/performance-over-time/index/network/awin/dateRange/thisMonth?dateType=validation"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button
                                      auto
                                      color="secondary"
                                      css={{
                                        bgColor: "#04999e",
                                        minWidth: "11rem",
                                      }}
                                    >
                                      Rechnung Zahlen
                                    </Button>
                                  </a>
                                </Grid>
                              ) : (
                                <></>
                              )}

                              {bi_report !== "" ? (
                                <Grid>
                                  <a
                                    href={bi_report}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button
                                      auto
                                      color="secondary"
                                      css={{
                                        bgColor: "#04999e",
                                        minWidth: "11rem",
                                      }}
                                    >
                                      BI Report
                                    </Button>
                                  </a>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Card.Body>
                </Card>
              </Grid>
            </Row>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 my-3">
                  General Information ::
                  {isEditing ? (
                    <Textarea
                      bordered
                      fullWidth
                      minRows={6}
                      value={generalinformation}
                      onChange={(e) => setGeneralInformation(e.target.value)}
                      onBlur={handleBlur}
                      autoFocus
                    />
                  ) : (
                    <pre
                      className="border p-2"
                      onDoubleClick={handleDoubleClick}
                    >
                      {generalinformation}
                    </pre>
                  )}
                </div>
              </div>
            </div>
          </Card>

          <Modal
            closeButton
            preventClose
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
            width="60rem"
          >
            <Modal.Body>
              <Row>
                {advertisercontact.map((person, index) => (
                  <Col>
                    <h2>Person {index + 1}</h2>
                    <Input
                      label="Name"
                      width="20rem"
                      value={person?.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                    />
                    <Input
                      label="Email"
                      width="20rem"
                      type="email"
                      value={person?.email}
                      onChange={(e) =>
                        handleInputChange(index, "email", e.target.value)
                      }
                    />
                    <Input
                      label="Telephone"
                      width="20rem"
                      type="number"
                      value={person?.telephone}
                      onChange={(e) =>
                        handleInputChange(index, "telephone", e.target.value)
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                auto
                onClick={(e) => handleeditcontactperson(advertisercontact)}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <Grid xl={5} xs={5} sm={6}>
            <Card variant="bordered" isHoverable css={{ bgColor: "#ededed" }}>
              <Card.Body>
                <Grid xl={15} xs={15} sm={20} justify="right">
                  <div className="container">
                    <div className="row">
                      {network === "awin" || network === "retailads" ? (
                        <div className="col">
                          <div className="row">
                            <Grid.Container gap={2}>
                              <Grid xl={4.8} xs={10} sm={7.5}>
                                <Input
                                  width="100px"
                                  label="Start Date"
                                  type="date"
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </Grid>
                              <Spacer x={-1}></Spacer>
                              <Grid xl={2} xs={10} sm={5}>
                                <Input
                                  width="100px"
                                  label="End Date"
                                  type="date"
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </Grid>

                              <Grid justify="center" xl={7.5} xs={10} sm={14}>
                                <Link
                                  to={{
                                    pathname: "/adv_pub",
                                    state: {
                                      startDate:
                                        startDate !== ""
                                          ? startDate
                                          : moment()
                                              .startOf("year")
                                              .format("YYYY-MM-DD"),
                                      endDate:
                                        endDate !== ""
                                          ? endDate
                                          : moment()
                                              .endOf("year")
                                              .format("YYYY-MM-DD"),
                                      advertiserId: advertiserId,
                                      advertiserName: advertiserName,
                                    },
                                  }}
                                >
                                  <button
                                    type="button"
                                    class="btn "
                                    style={{
                                      background: "#fc9d0b",
                                      color: "white",
                                    }}
                                  >
                                    Search Publisher
                                  </button>
                                </Link>
                              </Grid>
                            </Grid.Container>
                            {network === "awin" ? (
                              <Grid xl={3} xs={4} sm={3}>
                                <a
                                  href={
                                    "https://ui.awin.com/awin/merchant/" +
                                    advertiserId +
                                    "/affiliate-approval"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button css={{ bgColor: "#04999e" }} auto>
                                    Publisherfreigaben
                                  </Button>
                                </a>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            <Spacer x={2}></Spacer>

                            {network === "awin" ? (
                              <Grid xl={5} xs={5} sm={5}>
                                <a
                                  href={
                                    "https://ui.awin.com/merchant/" +
                                    advertiserId +
                                    "/report/performance-over-time/index/network/awin/dateRange/thisMonth?dateType=validation"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    auto
                                    color="secondary"
                                    css={{ bgColor: "#04999e" }}
                                  >
                                    Freigaben aktueller Monat
                                  </Button>
                                </a>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row">
                            <Grid xl={3} xs={6} sm={3}>
                              <Link
                                to={
                                  "/advertiserservice?id=" +
                                  advertiserId +
                                  "&token=" +
                                  token
                                }
                                target="_blank"
                              >
                                <Button css={{ bgColor: "#04999e" }} auto>
                                  Generate Report
                                </Button>
                              </Link>
                            </Grid>
                            <Spacer x={2}></Spacer>
                            {network === "awin" ? (
                              <Grid xl={3} xs={3} sm={3}>
                                <a
                                  href={
                                    "https://ui.awin.com/merchant/" +
                                    advertiserId +
                                    "/report/transactions/export/network/awin/dateType/transaction/transactionType/pending/perPage/10/page/1/timezone/Europe-Paris/reportName/transactions/merchantId/" +
                                    advertiserId +
                                    "/transactionState/pending/pageNumber/1/reportIdentifier/transactionsReport/dynamicDateRange/custom/start/" +
                                    advstartdate +
                                    "/end/" +
                                    moment()
                                      .subtract(daysback, "days")
                                      .format("YYYY-MM-DD") +
                                    "/hideBonusTransactions/1/amended/0/exportType/excel"
                                  }
                                >
                                  <Button css={{ bgColor: "#04999e" }} auto>
                                    Generate Excel-Sheet
                                  </Button>
                                </a>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Grid xl={4} xs={20} sm={5.5}>
                        <Card variant="bordered">
                          <Card.Header>
                            <Grid xl={5} xs={5}>
                              <ReactCountryFlag
                                countryCode={advertiserName.split(" ").pop()}
                                svg
                                style={{
                                  width: "4rem",
                                  height: "4rem",
                                }}
                                title="US"
                              />
                            </Grid>
                            <Grid xl={20} sm={20}>
                              {logourl ? (
                                <img
                                  src={logobaseurl + logourl}
                                  alt="logo img"
                                />
                              ) : (
                                "No Logo"
                              )}
                            </Grid>
                          </Card.Header>
                          <Card.Body>
                             <Grid justify="center">
                              <Text b>Address will be here</Text>
                            </Grid>
                          </Card.Body>
                        </Card>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Card.Body>
            </Card>
          </Grid> */}
        </Grid.Container>

        {/* </Card.Header>
                  <Card.Body>
                    <Grid.Container gap={2}>
                      <Grid xl={6} sm={6}>
                        <Input
                          width="100px"
                          label="Start Date"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Grid>
                      <Grid xl={5} sm={5}>
                        <Input
                          width="100px"
                          label="End Date"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Grid>
                      <Grid justify="center" xl>
                        <Link
                          to={{
                            pathname: "/adv_pub",
                            state: {
                              startDate:
                                startDate !== ""
                                  ? startDate
                                  : moment()
                                      .startOf("year")
                                      .format("YYYY-MM-DD"),
                              endDate:
                                endDate !== ""
                                  ? endDate
                                  : moment().endOf("year").format("YYYY-MM-DD"),
                              advertiserId: ele.advertiserId,
                              advertiserName: ele.advertiserName,
                            },
                          }}
                        >
                          <button type="button" class="btn btn-outline-dark">
                            Search Publisher
                          </button>
                        </Link>
                      </Grid>
                    </Grid.Container>

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/awin/merchant/" +
                            ele.advertiserId +
                            "/affiliate-approval"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Publisherfreigaben
                          </Button>
                        </a>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={5} sm={5}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/performance-over-time/index/network/awin/dateRange/thisMonth?dateType=validation"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Freigaben aktueller Monat
                          </Button>
                        </a>
                      </Grid>
                    </Grid>

                    <Spacer y={-2} />

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <Link
                          to={
                            "/advertiserservice?id=" +
                            ele.advertiserId +
                            "&token=" +
                            ele.token
                          }
                          target="_blank"
                        >
                          <Button bordered color="gradient" auto>
                            Generate Report
                          </Button>
                        </Link>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/transactions/export/network/awin/dateType/transaction/transactionType/pending/perPage/10/page/1/timezone/Europe-Paris/reportName/transactions/merchantId/" +
                            ele.advertiserId +
                            "/transactionState/pending/pageNumber/1/reportIdentifier/transactionsReport/dynamicDateRange/custom/start/2021-01-01/end/" +
                            moment()
                              .subtract(ele.daysback, "days")
                              .format("YYYY-MM-DD") +
                            "/hideBonusTransactions/1/amended/0/exportType/excel"
                          }
                        >
                          <Button bordered color="gradient" auto>
                            Generate Excel-Sheet
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Card.Body> */}
        <Grid.Container gap={2} justify="left" css={{ bgColor: "White" }}>
          <Grid xs={20} xl={4.5} sm={4.5}>
            <Bar
              data={week_state}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Short week summary",
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    min: 0,
                    ticks: {
                      beginAtZero: true,
                    },
                    grid: {
                      drawOnChartArea: false,
                    },
                    position: "right",
                    stacked: true,
                  },
                },
                y1: {
                  min: 0,
                  ticks: {
                    beginAtZero: true,
                  },
                  grid: {
                    drawOnChartArea: false,
                  },
                  position: "left",
                },
              }}
            />
          </Grid>
          <Grid xs={20} xl={3.5} sm={3.5}>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th colSpan="3" style={{ color: "black" }}>
                      Last Week Top Publisher
                    </th>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </thead>
                {lastweekpub.length > 0 ? (
                  <>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Publisher Name</th>
                      <th scope="col">Sales</th>
                    </tr>
                    <tbody>
                      {lastweekpub.map((publisher, index) => {
                        return (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <th scope="row">{index + 1}</th>
                            <td>{publisher.publisherName}</td>
                            <td>{publisher.count}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <div
                    style={{
                      color: "blue",
                      marginTop: "1rem",
                      marginLeft: "0.7rem",
                    }}
                  >
                    Sorry, No Publisher Found!
                  </div>
                )}
              </table>
            </div>
          </Grid>

          <Grid xs={20} xl={3.5} sm={3.5}>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th colSpan="3" style={{ color: "black" }}>
                      This Year Top Publisher
                    </th>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </thead>
                {yearpub.length > 0 ? (
                  <>
                    <tr>
                      <th scope="col">Index</th>
                      <th scope="col">Publisher Name</th>
                      <th scope="col">Sales</th>
                    </tr>
                    <tbody>
                      {yearpub.map((publisher, index) => {
                        return (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <th scope="row">{index + 1}</th>
                            <td>{publisher.publisherName}</td>
                            <td>{publisher.count}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <div
                    style={{
                      color: "red",
                      marginTop: "1rem",
                      marginLeft: "0.7rem",
                    }}
                  >
                    Sorry, No Publisher Found!
                  </div>
                )}
              </table>
            </div>
          </Grid>
        </Grid.Container>

        <Grid.Container gap={2} justify="left">
          <Grid xs={20} xl={4.5} sm={4}>
            <div className="container">
              <Grid>
                <Collapse.Group splitted css={{ width: "stretch" }}>
                  <Collapse title="Personal Task" expanded>
                    <div style={{ height: "20rem", overflow: "auto" }}>
                      <Grid.Container justify="right">
                        <Grid xl={6} xs={6} sm={6} justify="right">
                          <PersonalTask
                            advertiserId={advertiserId}
                            getDatafromPersonalTask={getDatafromPersonalTask}
                          />
                        </Grid>
                      </Grid.Container>
                      <ul style={{ listStyleType: "inherit" }}>
                        {personaltask.map((item, index) => {
                          return (
                            <Grid.Container gap={0.1} key={index}>
                              <Grid xl={9} xs={9} sm={8.9} justify="left">
                                <Checkbox
                                  color="gradient"
                                  isSelected={item.isdone}
                                  onChange={(value) =>
                                    handletaskdone(value, item._id, "personal")
                                  }
                                >
                                  <div
                                    style={{
                                      textDecoration: item.isdone
                                        ? "line-through"
                                        : null,
                                      textDecorationColor: item.isdone
                                        ? "grey"
                                        : null,
                                      color:
                                        item.isdone === false &&
                                        item.tasktype !== "Selected Days" &&
                                        moment(item.specialdate).add(1, "day") <
                                          moment()
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {item.task_description}
                                  </div>
                                </Checkbox>
                              </Grid>
                              <Grid xl={3} xs={3} sm={3} justify="right">
                                <Grid>
                                  <Tooltip
                                    placement="left"
                                    content={
                                      item.tasktype === "Selected Days"
                                        ? item.weektype +
                                          " :: " +
                                          item.selecteddays
                                        : item.tasktype ===
                                          "Before Special Date"
                                        ? "Before :: " +
                                          moment(item.specialdate).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "On :: " +
                                          moment(item.specialdate).format(
                                            "DD/MM/YYYY"
                                          )
                                    }
                                    trigger="hover"
                                  >
                                    <BsInfoCircleFill
                                      color="gradient"
                                      textColor="blue"
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid.Container>
                          );
                        })}
                      </ul>
                    </div>
                  </Collapse>
                  <Collapse title="Daily Task">
                    <div
                      style={{
                        height: "5rem",
                        overflow: "auto",
                      }}
                    >
                      <DragDropContext onDragEnd={dailyhandleEnd}>
                        <Droppable droppableId="daily-to-dos">
                          {(provided) => (
                            <ul
                              style={{ listStyleType: "initial" }}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {dailytask.map((item, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={index.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      key={index}
                                      className={
                                        snapshot.isDragging
                                          ? "selected"
                                          : "not-selected"
                                      }
                                    >
                                      <Grid.Container gap={0.1}>
                                        <Grid
                                          xl={9}
                                          xs={9}
                                          sm={8.9}
                                          justify="left"
                                          css={{
                                            textAlign: "justify",
                                          }}
                                        >
                                          <Checkbox
                                            color="gradient"
                                            defaultSelected={item.isdone.isdone}
                                            onChange={(value) =>
                                              handletaskdone(
                                                value,
                                                item._id,
                                                "daily"
                                              )
                                            }
                                          >
                                            {item.task_description}
                                          </Checkbox>
                                        </Grid>
                                        <Grid
                                          xl={usertype === 111 ? 1.8 : 3}
                                          xs={usertype === 111 ? 1.8 : 3}
                                          sm={usertype === 111 ? 1.8 : 3}
                                          justify="right"
                                        >
                                          {item.isdone.isdone === true ? (
                                            <>
                                              <Grid>
                                                <Tooltip
                                                  placement="left"
                                                  content={
                                                    "Time - " +
                                                    moment(
                                                      item.isdone.donetime
                                                    ).format(
                                                      "DD/MM/YYYY - HH:mm:ss"
                                                    )
                                                  }
                                                  trigger="hover"
                                                >
                                                  <Avatar
                                                    text={item.username}
                                                    color="gradient"
                                                    textColor="white"
                                                  />
                                                </Tooltip>
                                              </Grid>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                        {usertype === 111 ? (
                                          <Grid justify="right">
                                            <Button
                                              auto
                                              bordered
                                              color="error"
                                              disabled={!item.isdone}
                                              onPress={(e) =>
                                                handledeletetask(
                                                  item._id,
                                                  "daily"
                                                )
                                              }
                                            >
                                              <BsTrashFill color="red" />
                                            </Button>
                                          </Grid>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid.Container>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Collapse>
                  <Collapse title="Weekly Task">
                    <div style={{ height: "15rem", overflow: "auto" }}>
                      <DragDropContext onDragEnd={weeklyhandleEnd}>
                        <Droppable droppableId="weekly-to-dos">
                          {(provided) => (
                            <ul
                              style={{ listStyleType: "unset" }}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {weeklytask.map((item, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={index.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      key={index}
                                      className={
                                        snapshot.isDragging
                                          ? "selected"
                                          : "not-selected"
                                      }
                                    >
                                      <Grid.Container gap={0.1}>
                                        <Grid
                                          xl={9}
                                          xs={9}
                                          sm={8.9}
                                          css={{ textAlign: "justify" }}
                                          justify="left"
                                        >
                                          <Checkbox
                                            color="gradient"
                                            defaultSelected={item.isdone.isdone}
                                            onChange={(value) =>
                                              handletaskdone(
                                                value,
                                                item._id,
                                                "weekly"
                                              )
                                            }
                                          >
                                            {item.task_description}
                                          </Checkbox>
                                        </Grid>
                                        <Grid
                                          xl={usertype === 111 ? 1.8 : 3}
                                          xs={usertype === 111 ? 1.8 : 3}
                                          sm={usertype === 111 ? 1.8 : 3}
                                          justify="right"
                                        >
                                          {item.isdone.isdone === true ? (
                                            <>
                                              <Grid>
                                                <Tooltip
                                                  placement="left"
                                                  content={
                                                    "Time - " +
                                                    moment(
                                                      item.isdone.donetime
                                                    ).format(
                                                      "DD/MM/YYYY - HH:mm:ss"
                                                    )
                                                  }
                                                  trigger="hover"
                                                >
                                                  <Avatar
                                                    text={item.username}
                                                    color="gradient"
                                                    textColor="white"
                                                  />
                                                </Tooltip>
                                              </Grid>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                        {usertype === 111 ? (
                                          <Grid justify="right">
                                            <Button
                                              auto
                                              bordered
                                              color="error"
                                              disabled={!item.isdone}
                                              onPress={(e) =>
                                                handledeletetask(
                                                  item._id,
                                                  "weekly"
                                                )
                                              }
                                            >
                                              <BsTrashFill color="red" />
                                            </Button>
                                          </Grid>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid.Container>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Collapse>
                  <Collapse title="Monthly Task">
                    <div style={{ height: "5rem", overflow: "auto" }}>
                      <DragDropContext onDragEnd={monthlyhandleEnd}>
                        <Droppable droppableId="monthly-to-dos">
                          {(provided) => (
                            <ul
                              style={{ listStyleType: "initial" }}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {monthlytask.map((item, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={index.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      key={index}
                                      className={
                                        snapshot.isDragging
                                          ? "selected"
                                          : "not-selected"
                                      }
                                    >
                                      <Grid.Container gap={0.1}>
                                        <Grid
                                          xl={9}
                                          xs={9}
                                          sm={8.9}
                                          css={{ textAlign: "justify" }}
                                          justify="left"
                                        >
                                          <Checkbox
                                            color="gradient"
                                            defaultSelected={item.isdone.isdone}
                                            onChange={(value) =>
                                              handletaskdone(
                                                value,
                                                item._id,
                                                "monthly"
                                              )
                                            }
                                          >
                                            {item.task_description}
                                          </Checkbox>
                                        </Grid>
                                        <Grid
                                          xl={usertype === 111 ? 1.8 : 3}
                                          xs={usertype === 111 ? 1.8 : 3}
                                          sm={usertype === 111 ? 1.8 : 3}
                                          justify="right"
                                        >
                                          {item.isdone.isdone === true ? (
                                            <>
                                              <Grid>
                                                <Tooltip
                                                  placement="left"
                                                  content={
                                                    "Time - " +
                                                    moment(
                                                      item.isdone.donetime
                                                    ).format(
                                                      "DD/MM/YYYY - HH:mm:ss"
                                                    )
                                                  }
                                                  trigger="hover"
                                                >
                                                  <Avatar
                                                    text={item.username}
                                                    color="gradient"
                                                    textColor="white"
                                                  />
                                                </Tooltip>
                                              </Grid>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                        {usertype === 111 ? (
                                          <Grid justify="right">
                                            <Button
                                              auto
                                              bordered
                                              color="error"
                                              disabled={!item.isdone}
                                              onPress={(e) =>
                                                handledeletetask(
                                                  item._id,
                                                  "monthly"
                                                )
                                              }
                                            >
                                              <BsTrashFill color="red" />
                                            </Button>
                                          </Grid>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid.Container>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Collapse>
                </Collapse.Group>{" "}
                <Text h3>Publisher Notes</Text>
                <Card css={{ padding: "1rem" }}>
                  {advpubnotes.map((ap, index) => {
                    return (
                      <div>
                        <div>
                          <Link
                            to={{
                              pathname: "/publisher",
                              state: {
                                publishermothername: ap.publishermothername,
                                allnetwork: allnetwork,
                              },
                              search: `?publishermothername=${ap.publishermothername}`,
                            }}
                          >
                            <span
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              {ap.publishermothername}{" "}
                            </span>
                          </Link>
                          <span style={{ color: "grey", fontStyle: "italic" }}>
                            {" "}
                            Last updated by :-: {ap.createdby}{" "}
                          </span>
                          <span>
                            {" "}
                            ({moment(ap.created_time).format("DD-MM-YYYY")})
                          </span>{" "}
                        </div>
                        <pre
                          style={{
                            padding: 1,
                            margin: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {ap.notedetail}
                        </pre>
                      </div>
                    );
                  })}
                </Card>
              </Grid>
            </div>
          </Grid>

          <Grid xs={20} xl={7.5} sm={8}>
            <div className="container">
              <Row justify="center">
                <Text h3>All Notes</Text>
                <Spacer x={1} />
                <PersonalNotes
                  advertiserId={advertiserId}
                  advertiserName={advertiserName}
                  getDatafromPersonalNotes={getDatafromPersonalNotes}
                />
              </Row>
              {notes.length <= 0 ? (
                <></>
              ) : (
                <Card css={{ padding: "1rem" }}>
                  {notes.map((note, index) => {
                    return (
                      <div onDoubleClick={() => handleEditNote(index)}>
                        <div>
                          <span className="fw-bold">
                            {moment(note.created_time).format("DD-MM-YYYY")}
                          </span>{" "}
                          <span style={{ color: "grey", fontStyle: "italic" }}>
                            Last updated by :-: {note.createdby}
                          </span>
                        </div>
                        {editingIndex === index ? (
                          <div>
                            <Textarea
                              bordered
                              value={note.notedetail}
                              fullWidth
                              minRows={6}
                              onChange={(e) => handleChangeNote(e, index)}
                              onBlur={(e) => handleBlurNote(index)}
                              autoFocus
                            />
                          </div>
                        ) : (
                          <pre
                            style={{
                              padding: 1,
                              margin: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {note.notedetail}
                          </pre>
                        )}
                      </div>
                    );
                  })}
                </Card>
              )}
            </div>
          </Grid>
        </Grid.Container>

        {/* <Grid.Container gap={2}>
          <Grid xl={3} xs={3} sm={3} justify="left">
            <Card>
              <Card.Body>
                <Grid.Container justify="center">
                  <Grid>
                    <Text b css={{ fontSize: "$2xl" }}>
                      Daily Task
                    </Text>
                  </Grid>
                </Grid.Container>
                <hr />
                
              </Card.Body>
            </Card>
          </Grid>
          <Grid xl={3} xs={3} sm={3}>
            <Card>
              <Card.Body>
                <Grid.Container justify="center">
                  <Grid>
                    <Text b css={{ fontSize: "$2xl" }}>
                      Weekly Task
                    </Text>
                  </Grid>
                </Grid.Container>
                <hr />
                
              </Card.Body>
            </Card>
          </Grid>
          <Grid xl={3} xs={3} sm={3}>
            <Card>
              <Card.Body>
                <Grid.Container justify="center">
                  <Grid>
                    <Text b css={{ fontSize: "$2xl" }}>
                      Monthly Task
                    </Text>
                  </Grid>
                </Grid.Container>
                <hr />
                <ol>
                  <li>To Do - 1</li>
                  <li>To Do - 2</li>
                  <li>To Do - 3</li>
                  <li>To Do - 4</li>
                  <li>To Do - 5</li>
                </ol>
              </Card.Body>
            </Card>
          </Grid>
          <Grid xl={3} xs={3} sm={3}>
            <Card>
              <Card.Body>
                <Grid.Container justify="center">
                  <Grid>
                    <Text b css={{ fontSize: "$2xl" }}>
                      Personal Task
                    </Text>
                  </Grid>
                </Grid.Container>
                <hr />
                <ol>
                  <li>To Do - 1</li>
                  <li>To Do - 2</li>
                  <li>To Do - 3</li>
                  <li>To Do - 4</li>
                  <li>To Do - 5</li>
                </ol>
              </Card.Body>
            </Card>
          </Grid>
        </Grid.Container> */}
      </div>
    </div>
  );
}

export default Advertiser;
