import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Grid, Text, Button, Table, Checkbox } from "@nextui-org/react";
import ReactCountryFlag from "react-country-flag";

import moment from "moment";
import Adv_Pub_DataService from "../Services/advertiserService";
import Task_Service from "../Services/taskService";

import Navbar from "./Navigationbar";
import { logobaseurl } from "../Services/Http-Common";

function Homepage(props) {
  const history = useHistory();

  const embedUrl =
    "https://app.powerbi.com/view?r=eyJrIjoiMzhkY2Y3OTUtY2I0MS00ODAzLTk0NTEtMGE1OTAwYzllODdiIiwidCI6ImQ5ZTE0YzFkLTA2MzAtNGExYS04ZWU5LTExZDVlYTQ1NzA3ZCJ9"; // Replace {value} with your report ID or other parameters

  const publisher_bi_report =
    "https://app.powerbi.com/view?r=eyJrIjoiOTM1YzVjZmMtNjJlOC00ZjY2LTgxNjgtNWIyMDEyM2VmMGNlIiwidCI6ImQ5ZTE0YzFkLTA2MzAtNGExYS04ZWU5LTExZDVlYTQ1NzA3ZCJ9";

  const [superpowerbutton, setSuperpowerButton] = useState(false);
  const [checkedSuperButton, setCheckedSuperButton] = useState(false);
  const [weeklyoverviewtask, setWeeklyOverview] = useState([]);

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  const [advertiserArray, setAdvertiserArray] = useState([]);
  const [todaystask, setTodaysTask] = useState([]);

  useEffect(
    (e) => {
      Adv_Pub_DataService.getAllActiveAdvertiser(null)
        .then((response) => {
          if (response.status === 200) {
            setAdvertiserArray(response.data.data.data);
            setWeeklyOverview(response.data.data.weeklyoverviewtask);
            setTodaysTask(response.data.data.todaystask);
            setSuperpowerButton(response.data.data.issuperpower);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    },
    [history]
  );

  let columns = [{ name: "Advertisers", uid: "Advertisers" }];

  weeklyoverviewtask.forEach((task) => {
    columns.push({ name: task.task_description, uid: task.task_description });
  });

  const getAllAdvertiser = (superpowerbuttonstatus) => {
    Adv_Pub_DataService.getAllActiveAdvertiser(superpowerbuttonstatus)
      .then((response) => {
        setAdvertiserArray([]);
        if (response.status === 200) {
          setAdvertiserArray(response.data.data.data);
          setSuperpowerButton(response.data.data.issuperpower);
          setCheckedSuperButton(!checkedSuperButton);
          // setWeeklyOverview(response.data.data.weeklyoverviewtask);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const handletaskdone = (isdone, taskId, tasktype, advertiserId) => {
    Task_Service.update_task_done(isdone, taskId, advertiserId, tasktype)
      .then((response) => {
        if (response.status === 200) {
          const index = weeklyoverviewtask.findIndex(function (i) {
            return i._id === taskId;
          });
          if (index >= 0) {
            const advindex = weeklyoverviewtask[index].advertisers.findIndex(
              function (i) {
                return i.advertiserId === advertiserId;
              }
            );
            if (advindex >= 0) {
              weeklyoverviewtask[index].advertisers[advindex].isdone = isdone;
            }
          }

          const updatedweektaskview = weeklyoverviewtask;
          setWeeklyOverview(updatedweektaskview);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      <Grid.Container gap={2} justify="right">
        <Grid>
          <a
            href={publisher_bi_report}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button type="button" class="btn btn-outline-dark">
              ALL - Weekly
            </button>
          </a>
        </Grid>
        <Grid justify="right">
          <Link
            to={{
              pathname: "/adv_pub",
              state: {
                startDate: moment().startOf("year").format("YYYY-MM-DD"),
                endDate: moment().endOf("year").format("YYYY-MM-DD"),
                advertiserId: "All",
                advertiserName: "All Advertisers",
              },
            }}
          >
            <button type="button" class="btn btn-outline-dark">
              All Publisher - Report
            </button>
          </Link>
        </Grid>
      </Grid.Container>
      {/* <div style={{ backgroundColor: "lightgrey", margin: "2rem " }}>
        <Grid.Container gap={2.5} justify="center">
          {advertiserArray.map((ele, index) => {
            return (
              <Grid xl={3} key={index}>
                <Card
                  isPressable
                  isHoverable
                  variant="bordered"
                  css={{
                    mw: "400px",
                    bgColor: !ele.isYesterdayActive ? "LightPink" : "White",
                  }}
                >
                  <Card.Header>
                    <Grid xl={11} sm={11}>
                      <Text b>{ele.advertiserName}</Text>
                    </Grid>
                    <Grid xl={5} sm={5}>
                      <ReactCountryFlag
                        countryCode={ele.advertiserName.split(" ").pop()}
                        svg
                        style={{
                          width: "2em",
                          height: "2em",
                        }}
                        title="US"
                      />
                    </Grid>

                    <Grid xl={20} sm={20}>
                      {ele.logourl ? (
                        <img src={logobaseurl + ele.logourl} alt="logo img" />
                      ) : (
                        "NO LOGO"
                      )}
                    </Grid>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Container gap={2}>
                      <Grid xl={6} sm={6}>
                        <Input
                          width="100px"
                          label="Start Date"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Grid>
                      <Grid xl={5} sm={5}>
                        <Input
                          width="100px"
                          label="End Date"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Grid>
                      <Grid justify="center" xl>
                        <Link
                          to={{
                            pathname: "/adv_pub",
                            state: {
                              startDate:
                                startDate !== ""
                                  ? startDate
                                  : moment()
                                      .startOf("year")
                                      .format("YYYY-MM-DD"),
                              endDate:
                                endDate !== ""
                                  ? endDate
                                  : moment().endOf("year").format("YYYY-MM-DD"),
                              advertiserId: ele.advertiserId,
                              advertiserName: ele.advertiserName,
                            },
                          }}
                        >
                          <button type="button" class="btn btn-outline-dark">
                            Search Publisher
                          </button>
                        </Link>
                      </Grid>
                    </Grid.Container>

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/awin/merchant/" +
                            ele.advertiserId +
                            "/affiliate-approval"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Publisherfreigaben
                          </Button>
                        </a>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={5} sm={5}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/performance-over-time/index/network/awin/dateRange/thisMonth?dateType=validation"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Freigaben aktueller Monat
                          </Button>
                        </a>
                      </Grid>
                    </Grid>

                    <Spacer y={-2} />

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <Link
                          to={
                            "/advertiserservice?id=" +
                            ele.advertiserId +
                            "&token=" +
                            ele.token
                          }
                          target="_blank"
                        >
                          <Button bordered color="gradient" auto>
                            Generate Report
                          </Button>
                        </Link>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/transactions/export/network/awin/dateType/transaction/transactionType/pending/perPage/10/page/1/timezone/Europe-Paris/reportName/transactions/merchantId/" +
                            ele.advertiserId +
                            "/transactionState/pending/pageNumber/1/reportIdentifier/transactionsReport/dynamicDateRange/custom/start/2021-01-01/end/" +
                            moment()
                              .subtract(ele.daysback, "days")
                              .format("YYYY-MM-DD") +
                            "/hideBonusTransactions/1/amended/0/exportType/excel"
                          }
                        >
                          <Button bordered color="gradient" auto>
                            Generate Excel-Sheet
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Card>
              </Grid>
            );
          })}
        </Grid.Container>
        <div style={{ margin: "2rem" }}>
          <Grid.Container justify="center">
            {superpowerbutton ? (
              !checkedSuperButton ? (
                <Button
                  shadow
                  auto
                  color="primary"
                  rounded
                  bordered
                  onClick={(e) => getAllAdvertiser("show")}
                >
                  See All Advertiser
                </Button>
              ) : (
                <Button
                  shadow
                  auto
                  color="primary"
                  rounded
                  bordered
                  onClick={(e) => getAllAdvertiser("hide")}
                >
                  Hide Other Advertiser
                </Button>
              )
            ) : (
              <></>
            )}
          </Grid.Container>
        </div>
      </div> */}
      <div style={{ padding: "2rem" }}>
        <Grid.Container justify="center" css={{ paddingBottom: "$10" }}>
          <Grid justify="center">
            <Text h1 size={40} weight="bold">
              Today's Task
            </Text>
          </Grid>
        </Grid.Container>
        <Grid.Container>
          <Card isHoverable css={{ bgColor: "#04999e" }}>
            <Card.Body
              css={{
                fontWeight: "$semibold",
              }}
            >
              <ol style={{ color: "white" }}>
                {todaystask.map((item, index) => {
                  return (
                    <li key={index + 1}>
                      <Grid.Container>
                        <Grid xl={6} xs={6} sm={6} justify="left">
                          {item.advertiserName} -:- {item.task_description}
                        </Grid>
                        <Grid xl={6} xs={6} sm={6} justify="right">
                          {item.tasktype === "Before Special Date"
                            ? "Deadline :: " +
                              moment(item.specialdate).format("DD-MM-YYYY")
                            : "TODAY"}
                        </Grid>
                      </Grid.Container>
                    </li>
                  );
                })}
              </ol>
            </Card.Body>
          </Card>
        </Grid.Container>
      </div>
      {/* Table for task weekly overview */}

      <div style={{ width: "100%", height: "100vh" }}>
        <iframe
          style={{ width: "100%", height: "100%", border: "none" }}
          src={embedUrl}
          allowFullScreen="true"
          title="Power BI Report"
        ></iframe>
      </div>

      <Table
        bordered
        aria-label="Example table with dynamic content & infinity pagination"
        css={{
          minWidth: "100%",
          height: "calc($space$20 * 10)",
          overflow: "auto",
          display: "block",
          paddingTop: "$0",
          marginTop: "$1",
        }}
        color="secondary"
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              key={column.uid}
              css={{
                position: "sticky",
                top: "$0",
                zIndex: "$max",
                width: "10%",
              }}
            >
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body>
          {advertiserArray.map((adv, advindex) => {
            return (
              <Table.Row key={advindex}>
                <Table.Cell key={advindex}>
                  {/* <ReactCountryFlag
                    countryCode={adv.advertiserName.split(" ").pop()}
                    svg
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    title="US"
                  />{" "}
                  {adv.advertiserName} */}
                  <Card
                    isPressable
                    isHoverable
                    variant="bordered"
                    css={{
                      w: "400px",

                      bgColor: !adv.isYesterdayActive ? "LightPink" : "White",
                    }}
                  >
                    <Link
                      to={{
                        pathname: "/advertiser",
                        state: {
                          //   _id: adv._id,
                          //   advertiserId: adv.advertiserId,
                          //   advertiserName: adv.advertiserName,
                          //   logourl: adv.logourl,
                          //   daysback: adv.daysback,
                          //   token: adv.token,
                          //   network: adv.network,
                          advertiserArray: advertiserArray,
                          //   startdate: adv.startdate,
                          //   standardcommission: adv.standardcommission,
                          //   promotionalCodes: adv.promotionalCodes,
                          //   generalinformation: adv.generalinformation,
                        },
                        search: `Id=${adv._id}`,
                      }}
                    >
                      <Card.Header>
                        <Grid xl={11} xs={6}>
                          <ReactCountryFlag
                            countryCode={adv.advertiserName.split(" ").pop()}
                            svg
                            style={{
                              width: "2em",
                              height: "2em",
                            }}
                          />

                          <Text weight="bold" css={{ fontFamily: "Segoe UI" }}>
                            {adv.advertiserName}
                          </Text>
                        </Grid>
                        <Grid xl={5} xs={5} sm={5}></Grid>

                        <Grid xl={20} xs={20}>
                          {adv.logourl ? (
                            <img
                              src={logobaseurl + adv.logourl}
                              alt="logo img"
                            />
                          ) : (
                            "NO LOGO"
                          )}
                        </Grid>
                      </Card.Header>
                    </Link>
                  </Card>
                </Table.Cell>

                {weeklyoverviewtask.map((task, index) => {
                  return (
                    <Table.Cell key={index}>
                      {task.advertisers.some(
                        (x) => x.advertiserId === adv.advertiserId
                      ) ? (
                        <Checkbox
                          color="gradient"
                          defaultSelected={
                            task.advertisers.some(
                              (x) =>
                                x.advertiserId === adv.advertiserId &&
                                x.isdone === true
                            )
                              ? true
                              : false
                          }
                          onChange={(value) =>
                            handletaskdone(
                              value,
                              task._id,
                              task.tasktype,
                              Number(adv.advertiserId)
                            )
                          }
                        ></Checkbox>
                      ) : (
                        <Text color="grey">X</Text>
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {/* <div style={{ margin: "2rem" }}>
        <Card>
          <Card.Body css={{ bgColor: "#ededed" }}>
            <Grid.Container gap={2} justify="center">
              {advertiserArray.map((ele, index) => {
                return (
                  <Grid xl={2} xs={6} sm={3} key={index} justify="center">
                    <Card
                      isPressable
                      isHoverable
                      variant="bordered"
                      css={{
                        w: "400px",

                        bgColor: !ele.isYesterdayActive ? "LightPink" : "White",
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/advertiser",
                          state: {
                            advertiserId: ele.advertiserId,
                            advertiserName: ele.advertiserName,
                            logourl: ele.logourl,
                            daysback: ele.daysback,
                            token: ele.token,
                          },
                        }}
                      >
                        <Card.Header>
                          <Grid xl={11} xs={6}>
                            <Text
                              weight="bold"
                              css={{ fontFamily: "Segoe UI" }}
                            >
                              {ele.advertiserName}
                            </Text>
                          </Grid>
                          <Grid xl={5} xs={5} sm={5}>
                            <ReactCountryFlag
                              countryCode={ele.advertiserName.split(" ").pop()}
                              svg
                              style={{
                                width: "2em",
                                height: "2em",
                              }}
                              title="US"
                            />
                          </Grid>

                          <Grid xl={20} xs={20}>
                            {ele.logourl ? (
                              <img
                                src={logobaseurl + ele.logourl}
                                alt="logo img"
                              />
                            ) : (
                              "NO LOGO"
                            )}
                          </Grid>
                        </Card.Header>
                      </Link>
                    </Card>
                  </Grid>
                );
              })}
            </Grid.Container>
          </Card.Body>
        </Card>
      </div> */}
      <div style={{ margin: "2rem" }}>
        <Grid.Container justify="center">
          {superpowerbutton ? (
            !checkedSuperButton ? (
              <Button
                shadow
                auto
                color="primary"
                rounded
                bordered
                onClick={(e) => getAllAdvertiser("show")}
              >
                See All Advertiser
              </Button>
            ) : (
              <Button
                shadow
                auto
                color="primary"
                rounded
                bordered
                onClick={(e) => getAllAdvertiser("hide")}
              >
                Hide Other Advertiser
              </Button>
            )
          ) : (
            <></>
          )}
        </Grid.Container>
      </div>
    </div>
  );
}

export default Homepage;